export default function (arr, criteria) {
    return arr.reduce((obj, item, index) => {
        const key = typeof criteria === 'function' ? criteria(item, index) : item[criteria];

        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }

        obj[key].push(item);
        return obj;
    }, {});
};
