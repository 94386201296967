<template>
    <MainLayout>
        <div class="mt-16 px-6 py-4 flex flex-col flex-1 bg-white rounded-t-3xl">
            <div class="-mt-20">
							<Card :balance="employeeBalance"/>
            </div>

            <div class="mt-6 flex-1">
                <h2 class="text-gray-800 font-bold text-center">Ultimos movimientos</h2>

                <div class="mt-3 space-y-6">
                    <div 
                        v-for="(balances, date) in groupedBalances"
                        :key="date"
                    >
                        <p class="text-sm font-bold text-gray-400 text-center">{{ capitalize(date) }}</p>

                        <div class="mt-2 space-y-2">
                            <div
                                v-for="balance in balances"
                                :key="balance.id"
                                class="px-4 py-2 cursor-pointer bg-gray-100 rounded-xl"
                                :class="{ 'border border-red-500 bg-red-100 bg-opacity-50': balance.status === 'refunded' }"
                            >
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center flex-1 min-w-0 cursor-pointer">
                                        <template v-if="balance.type === 'gain'">
                                            <div class="flex-shrink-0">
                                                <div 
                                                    class="flex-shrink-0"
                                                >
                                                    <div 
                                                        class="w-8 h-8 flex items-center justify-center text-white rounded-full bg-green-400"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/></svg>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ml-4">
                                                <p class="font-bold text-gray-700 truncate">
                                                    {{ balance.session.firstName }}
                                                    {{ balance.session.lastName }}
                                                </p>

                                                <p class="text-xs text-gray-500">{{ balance.product?.name }}</p>
                                            </div>
                                        </template>

                                        <template v-else-if="balance.type === 'loss' && balance.product">
                                            <div class="flex-shrink-0">
                                                <div class="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-xl">
                                                    <img 
                                                        :src="balance.product.image"
                                                        class="w-full h-full object-cover rounded-xl"
                                                    />
                                                </div>
                                            </div>

                                            <div class="ml-4">
                                                <p class="font-bold text-gray-700 leading-none truncate">
                                                    {{ balance.product.name }}
                                                </p>

                                                <p class="truncate">
                                                    <span class="font-bold text-gray-700">{{ balance.quantity }}</span>
                                                    <span class="text-xs text-gray-500"> Uds</span>
                                                </p>
                                            </div>
                                        </template>

                                        <template v-else>
                                            <div class="flex-shrink-0">
                                                <div 
                                                    class="w-10 h-10 flex items-center justify-center text-white rounded-full bg-red-400"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18 12H6"/></svg>
                                                </div>
                                            </div>

                                            <div class="ml-4">
                                                <p class="text-lg font-bold text-gray-700 truncate">
                                                    {{ balance.session?.firstName }}
                                                    {{ balance.session?.lastName }}
                                                </p>
                                            </div>
                                        </template>
                                    </div>

                                    <div class="ml-3 text-right">
                                        <p class="text-xs text-gray-400">
                                            {{ time(balance.createdAt) }}
                                        </p>

                                        <div class="flex items-center">
                                            <div class="flex items-center font-bold">
                                                <span
                                                    :class="{
                                                        'text-red-500': balance.type === 'loss',
                                                        'text-green-500': balance.type === 'gain'
                                                    }"
                                                >
                                                    <span v-if="balance.type === 'loss'">-</span>
                                                    <span v-if="balance.type === 'gain'">+</span>
                                                    <span>{{ Math.abs(balance.amount).toFixed(1) }}€</span>
                                                </span>

                                                <svg xmlns="http://www.w3.org/2000/svg" class="mx-1 w-3 h-3" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M0 0h24v24H0z" stroke="none"/><path d="M5 12h14M15 16l4-4M15 8l4 4"/></svg>


                                                <span class="text-gray-700">
                                                    {{ balance.totalAmount.toFixed(1) }}€
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 flex items-center"> 
                                    <button 
                                        v-if="isRequestable(balance)"
                                        class="px-4 py-2 text-sm w-full bg-blue-200 text-blue-500 rounded-xl border border-blue-500"
                                        @click="requestBalance(balance)"
                                    >
                                        Pedir ahora
                                    </button>

                                    <button 
                                        v-if="isRefundable(balance)"
                                        class="ml-3  px-4 py-2 text-sm w-full bg-white text-red-500 rounded-xl border border-red-500"
                                        @click="refundBalance(balance)"
                                    >
                                        Cancelar pedido
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-8 -mx-6 px-6">
                        <div class=" flex space-x-2 overflow-x-auto scrolling-touch">
                            <div
                                v-for="page in lastPage"
                                :key="page"
                                class="px-3 py-1 flex-none bg-white border rounded-lg cursor-pointer"
                                :class="{
                                    'bg-indigo-500 text-white': page === currentPage,
                                }"
                                @click="getBalances(page)"
                            >
                                {{ page }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>
<script>
import { computed, ref } from "vue";
    
    import MainLayout from '@/layouts/main';
    import Card from '/src/components/card';

    import EmployeeBalance from "@/api/endpoints/EmployeeBalance.js";
    import Balance from "@/api/endpoints/Balance.js";
    import usePromise from "@/composables/use-promise.js";
    import usePagination from "@/composables/use-pagination.js";
    import { alert } from '@/components/alerts';

    import { state } from "@/store";
    import { format, time, parse } from '/src/utils/dates.js';
    import { es } from 'date-fns/locale';

    import emitter from "@/utils/emitter.js";
    import groupBy from '@/utils/group-by.js';
    import capitalize from '@/utils/capitalize.js';
    

    export default {
        name: 'Movements',
        components: {
            MainLayout,
            Card
        },
        setup() {
           const user = computed(() => state.user);

            const {
                data: balances = [],
                next: moreBalances,
                use: getBalances,
                currentPage,
                lastPage,
            } = usePagination(
                usePromise((p = 1) => {
                    return EmployeeBalance.paginate({
                        param: user.value.id,
                        query: {
                            search: "",
                            page: p,
                        },
                    });
                }),
                {
                    replace: true,
                }
            );

            getBalances();

            // grouped balances
            const groupedBalances = computed(() => {
                return groupBy(balances.value, (balance) => {
                    const date = parse(balance.createdAt, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date);
                    let formatted = format(date, 'PPPP', { locale: es });
                    formatted.charAt(0).toUpperCase();
                    
                    return formatted;
                });
            });


            function isRefundable(balance) {
                const date = balance.date && parse(balance.date, 'yyyy-MM-dd', new Date);
                const origin = new Date().setHours(0,0,0,0);

                const selectable = date && date.valueOf() >= origin.valueOf();                
                return selectable && balance.status === 'pending' && balance.type === 'loss';
            }

            function refundBalance(balance) {
                alert({
                    title: 'Cancelar pedido',
                    text: '¿Estás seguro de que quieres cancelar el pedido? Esta acción devolverá el dinero gastado a tu saldo.',
                    confirmLabel: 'Cancelar Pedido',
                    cancelLabel: 'Salir',
                    async confirm({ close }) {
                        await EmployeeBalance.refund(balance);
                        await getBalances();

                        emitter.emit('balance:change');

                        close();
                    }
                });
            }

            function isRequestable(balance) {
                if (!balance.product) {
                    return;
                }

                const qr = balance.product.section.qr.id;
                const section = balance.product.section.id;
                const excludeList = [
                    'dbc148bb-ffb1-47b1-b2ab-8af6f79a557d', // buffet
                    // 'bb76514e-d283-4279-9497-ac8966173547' // tostadas
                ];

                if (!(qr === '1795d491-9354-43a9-83d3-3801bca5fb54' && !excludeList.includes(section))) {
                    return false;
                }

                const date = balance.date && parse(balance.date, 'yyyy-MM-dd', new Date);
                const origin = new Date().setHours(0,0,0,0);

                const selectable = date && date.valueOf() >= origin.valueOf();                
                return selectable && balance.status === 'pending' && balance.type === 'loss';
            }

            function requestBalance(balance) {
                alert({
                    title: 'Confirmar hora del pedido',
                    text: `¿Quieres indicar que empiecen a preparar tu pedido?`,
                    confirmLabel: 'Pedir ahora',
                    cancelLabel: 'Más tarde',
                    async confirm({ close }) {
                        await Balance.update({
                            ...balance,
                            status: 'requested'
                        });

                        close();
                    }
                });
            }

					const employeeBalance = ref(0);
					async function getBalance() {
						employeeBalance.value = await EmployeeBalance.current(state.user.id);
					}
					getBalance();


					return {
                // balances
                balances,
                getBalances,
                currentPage,
                lastPage,
                groupedBalances,

                isRefundable,
                refundBalance,

                isRequestable,
                requestBalance,

								employeeBalance,
								getBalance,

							// others
                time,
                capitalize,
					};
        }
    }
</script>