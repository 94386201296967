import EndpointPrivate from '../EndpointPrivate.js';

class Balance extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'balances', param];
	}

	refund(attributes) {
		return this.request({
			url: `/food/v1/refund-balances/${this.primaryKey(attributes)}`,
			method: 'PUT',
			data: attributes
		});
	}
}

export default new Balance;