import { ref, watch, reactive, toRaw, toRefs } from 'vue';
import { unwrap } from './utils';

import { camelCase } from 'lodash-es';


const defaultOptions = {
    replace: false
};

export default function usePagination(props, options = defaultOptions) {    
    let data = ref([]);
    let meta = reactive({ currentPage: 0, lastPage: 1, total: 0 });
    let config = reactive({ ...options });

    function prev() {
        const { currentPage = 0 } = meta;
        
        if (currentPage > 1) {
            props.use(currentPage - 1);
        }
    };

    function next() {
        const { currentPage = 0, lastPage = 1 } = meta;
        
        if (currentPage < lastPage) {
            props.use(currentPage + 1);
        }
    };

    watch(
        () => unwrap(props.result),
        (result = {}) => {
            let { data: items = [], meta: links } = result;
            let metadata = { ...links  };

            for (const key in toRaw(metadata)) {
                meta[camelCase(key)] = metadata[key];
            }  

            if (options.replace) {
                data.value = [];
            }

            if (options.invert) {
                data.value.unshift(...items);
            } else {
                data.value.push(...items);
            }
        }
    );
    
    return {
        data,
        prev,
        next,
        ...toRefs(meta),
        ...props,
    };
};